var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('div',{staticClass:"page-width page-padding"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"question"},[_c('h2',{staticClass:"h3"},[_vm._v("\n        How do I use the bot?\n      ")]),_vm._v(" "),_c('p',[_vm._v("After the bot has been added to the server using "),_c('a',{staticClass:"external",attrs:{"href":_vm.$inviteLink}},[_vm._v("this invite link")]),_vm._v(", you can access the commands by using the "),_c('code',[_vm._v("/")]),_vm._v(" prefix.")]),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6),_vm._v(" "),_c('div',{staticClass:"question"},[_c('h2',{staticClass:"h3"},[_vm._v("\n        The bot is not working! What should I do?\n      ")]),_vm._v(" "),_c('p',[_vm._v("For further help and guidance with GW2Bot, please "),_c('a',{staticClass:"external",attrs:{"href":_vm.$supportServerLink,"target":"_blank"}},[_vm._v("join the support server")]),_vm._v("!")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('h1',[_vm._v("Frequently Asked Questions")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Type "),_c('code',[_vm._v("/")]),_vm._v(" for a full list of commands.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('h2',{staticClass:"h3"},[_vm._v("\n        How do I link my Guild Wars 2 account to GW2Bot?\n      ")]),_vm._v(" "),_c('p',[_vm._v("Follow the text instructions below or "),_c('a',{staticClass:"external",attrs:{"href":"https://www.youtube.com/watch?v=OHyP0xQmgjw","target":"_blank"}},[_vm._v("watch this video guide")]),_vm._v(".")]),_vm._v(" "),_c('ol',[_c('li',[_vm._v("Login to ArenaNet's website at "),_c('a',{staticClass:"external",attrs:{"href":"https://account.arena.net/","target":"_blank"}},[_vm._v("https://account.arena.net/")]),_vm._v(" with your Guild Wars 2 username and password, then go to the Applications tab and click the \"New Key\" button.")]),_vm._v(" "),_c('li',[_vm._v("Input a name for the new API key and select the permissions you wish to grant GW2Bot, then click \"Create API Key\". "),_c('em',[_vm._v("Note: some commands will not work unless you select the appropriate permissions.")])]),_vm._v(" "),_c('li',[_vm._v("Copy the new API key, then send a direct message on Discord to "),_c('code',[_vm._v("GW2Bot#2251")]),_vm._v(" with the following command to add a new API key: "),_c('code',[_vm._v("/key add (your API key)")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('h2',{staticClass:"h3"},[_vm._v("\n        Does the bot work in DMs?\n      ")]),_vm._v(" "),_c('p',[_vm._v("Yes. API keys are cross-server too, if you're in multiple servers with the bot.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('h2',{staticClass:"h3"},[_vm._v("\n        How do I setup game update notifications?\n      ")]),_vm._v(" "),_c('p',[_vm._v("First, make sure you have the \"Manage Server\" permission in the applicable Discord server.")]),_vm._v(" "),_c('p',[_vm._v("Provided you have that, point the bot to the channel where it'll post announcements, using "),_c('code',[_vm._v("/notifier update channel")]),_vm._v(".")]),_vm._v(" "),_c('p',[_vm._v("Make sure the bot has permissions to talk in that channel.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('h2',{staticClass:"h3"},[_vm._v("\n        What is guildsync?\n      ")]),_vm._v(" "),_c('p',[_vm._v("guildsync is a feature which allows you to sync your in-game guild ranks with Discord roles.")]),_vm._v(" "),_c('ul',[_c('li',[_vm._v("It requires leader permissions in-game.")]),_vm._v(" "),_c('li',[_vm._v("It is tied to your Guild Wars 2 account; if you remove your API key, guildsync will break.")]),_vm._v(" "),_c('li',[_vm._v("Syncing isn't instant - it can sometimes take up to 30 minutes before your settings are synced. To force a sync, you can use command "),_c('code',[_vm._v("/server sync_now")]),_vm._v(".")]),_vm._v(" "),_c('li',[_vm._v("Always ensure that GW2Bot is above the synced roles, or else the bot won't be able to assign them.")]),_vm._v(" "),_c('li',[_vm._v("You can modify and change permissions of the roles created by the bot.")]),_vm._v(" "),_c('li',[_vm._v("Only server members with an API key added to the bot will participate in the sync and no input is required from them.")]),_vm._v(" "),_c('li',[_vm._v("New members who add their API key after sync is setup will also be synced automatically.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('h2',{staticClass:"h3"},[_vm._v("\n        Why are the emojis not displaying correctly in my Discord server?\n      ")]),_vm._v(" "),_c('p',[_vm._v("You must grant the permission \"Use External Emojis\" to the "),_c('code',[_vm._v("@everyone")]),_vm._v(" role within your Discord server.")])])
}]

export { render, staticRenderFns }